import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../../state/actions'
import SearchBar from './SearchBar'
import OrderByDropdown from './OrderByDropdown'

function Header({ title, onCloseClick, texts }) {
  return (
    <div className="products-search-modal__header">
      <div className="products-search-modal__header-top">
        <div className="products-search-modal__title-wrapper">
          <span className="products-search-modal__title">{title}</span>
          <span className="products-search-modal__header-info">{texts.select_one_or_several_benchmark}</span>
        </div>

        <button type="button" onClick={onCloseClick} className="products-search-modal__cancel">
          {texts.cancel}
        </button>
      </div>

      <div className="products-search-modal__actions">
        <div className="products-search-modal__actions-left">
          <SearchBar />
        </div>
        <div className="products-search-modal__actions-right">
          <OrderByDropdown />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Header)
