import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Layout from '../../../../layout'
import Header from '../components/EditProject/Header'
import * as renaultProjectModeActionCreators from '../../state/actions'
import NotFoundPage from '../../../../pages/404'
import Body from '../components/EditProject/Body'
import { isRenaultProjectModeEnabled } from '../../../../../va-corejs-v3/utils'
import styles from '../../styles/EditProject.module.scss'
import { LoadingBar } from '../../../../components/loading_bar'

function EditProjectPage({ systemLoaded, location, initProject, authentication }) {
  const [found, setFound] = useState(false)
  const [loading, setLoading] = useState(true)
  const hasPermission = authentication?.user?.parsedPermissions?.renaultProjectMode

  useEffect(() => {
    if (systemLoaded) {
      const params = new URLSearchParams(location.search)

      if (params.has('id')) {
        initProject(params.get('id'))
          .then(() => {
            setFound(true)
          })
          .catch(error => {
            console.log(error)
            setFound(false)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }, [systemLoaded])

  if (!isRenaultProjectModeEnabled() || !hasPermission) {
    return (
      <Layout>
        <NotFoundPage />
      </Layout>
    )
  }

  return (
    <Layout>
      {!loading && !found && <NotFoundPage />}
      {loading && (
        <div className={`${styles.EditProjectPage} ${styles.EditProjectPage___loading}`}>
          <LoadingBar />
        </div>
      )}
      {!loading && found && (
        <div className={styles.EditProjectPage}>
          <Header />
          <div className={styles.EditProjectPage_main}>
            <Body />
          </div>
        </div>
      )}
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    authentication: state.authentication,
    systemLoaded: state.environment.systemLoaded,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(EditProjectPage)
