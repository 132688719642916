import React, { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { CircularProgress } from '@material-ui/core'
import { format } from 'date-fns'
import styles from '../../../styles/DateField.module.scss'
import CancelIcon from '../../../../../assets/svg/x-instr.svg'
import PenIcon from '../../../../../assets/svg/pen-sticky.svg'

// DOCUMENTATION
// Link to date picker documentation
// https://github.com/Hacker0x01/react-datepicker
// https://reactdatepicker.com/
//
const classNames = require('classnames')

function DateField({ selected, onChange, onConfirm, onCancel, dateFormat = 'dd-MM-yyyy', placeholder }) {
  const [value, setValue] = useState(selected && moment(selected).toDate())
  const [isSaving, setIsSaving] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const handleChange = async newValue => {
    setValue(newValue)
    setIsSaving(true)
    if (onChange) await onChange(newValue)
    setIsSaving(false)
  }

  const handleCancel = async () => {
    setValue()
    setIsSaving(true)
    if (onCancel) await onCancel()
    setIsSaving(false)
  }

  return (
    <div
      className={classNames(styles.DateField, {
        [styles.DateField___isSaving]: isSaving,
        [styles.DateField___isFocused]: isFocused,
      })}
    >
      <div className={styles.DateField_input}>
        <ReactDatePicker
          wrapperClassName={styles.DateField_input_datepickerWrapper}
          className={styles.DateField_input_datepickerWrapper_datepicker}
          selected={value}
          onChange={updatedValue => handleChange(updatedValue)}
          dateFormat={dateFormat}
          placeholderText={placeholder}
          onSelect={data => {
            setIsFocused(false)
            onConfirm(data && moment(data).toDate())
          }}
          onBlur={() => {
            setIsFocused(false)
            onConfirm(value && moment(value).toDate())
          }}
          onFocus={() => setIsFocused(true)}
          popperPlacement="bottom-start"
          popperClassName={styles.DateField_calendar}
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, -10],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                rootBoundary: 'viewport',
                tether: false,
                altAxis: true,
              },
            },
          ]}
        />
        <div className={styles.DateField_input_hiddenText}>{value ? format(value, dateFormat) : placeholder}</div>
      </div>
      {!value && !isSaving && (
        <button className={styles.DateField_button} type="button">
          <PenIcon className={styles.DateField_button_icon} />
        </button>
      )}
      {value && !isSaving && (
        <button className={styles.DateField_button} type="button" onClick={handleCancel}>
          <CancelIcon className={styles.DateField_button_icon} style={{ cursor: 'pointer' }} />
        </button>
      )}
      {isSaving && (
        <button className={styles.DateField_button} type="button">
          <CircularProgress color="inherit" size="0.8em" />
        </button>
      )}
    </div>
  )
}

export default DateField
