import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../../state/actions'
import Button from '../../../Common/Button'

function ValidateButton({ selectedIds, onConfirm, texts }) {
  return (
    <Button
      className="products-search-modal__validate"
      label={`${texts.validate}`}
      color="primary"
      size="large"
      onClick={() => {
        onConfirm(selectedIds)
      }}
    />
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    selectedIds: state.renaultProjectMode.searchProducts.selectedIds,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ValidateButton)
