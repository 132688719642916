import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import '../../../styles/switchers.scss'
import MilestoneIcon from '../../../../../assets/svg/flag.svg'
import ExplorationPhaseIcon from '../../../../../assets/svg/exploration-phase.svg'

function ProjectItemIcon({ type, width, height }) {
  switch (type) {
    case 'milestone': {
      return <MilestoneIcon width={width} height={height} />
    }
    case 'exploration-phase': {
      return <ExplorationPhaseIcon width={width} height={height} />
    }
    default: {
      return null
    }
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ProjectItemIcon)
