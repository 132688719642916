import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../store/actions'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import CreateScoringComponent from '../../../../../../components/scoring'

function CreateConfigurationModal({
  device,
  projectId,
  newProductId,
  closeCallback,
  initRenaultProjectModeConfiguration,
  resetRenaultProjectModeProductCreation,
  addProductsToProject,
  createRenaultProjectModeProduct,
  processSyncQueue,
  title,
}) {
  useEffect(() => {
    initRenaultProjectModeConfiguration()
    return () => {
      resetRenaultProjectModeProductCreation()
    }
  }, [])

  const createCallback = async () => {
    await createRenaultProjectModeProduct(newProductId)

    if (device.isOnline) {
      processSyncQueue()
    }

    await addProductsToProject([newProductId], projectId)

    closeCallback()
  }

  return <CreateScoringComponent title={title} createCallback={createCallback} closeCallback={closeCallback} />
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    device: state.device,
    newProductId: state.renaultProjectMode.createProduct.newProductId,
    projectId: state.renaultProjectMode.editProject.id,
  }
}

export default connect(mapStateToProps, {
  ...actionCreators,
  ...renaultProjectModeActionCreators,
})(CreateConfigurationModal)
