/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import ClickAwayListener from 'react-click-away-listener'
import { CircularProgress, TextareaAutosize } from '@material-ui/core'
import * as actionCreators from '../../../../../store/actions'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import PenIcon from '../../../../../assets/svg/pen-sticky.svg'
import CancelIcon from '../../../../../assets/svg/x-instr.svg'
import CheckIcon from '../../../../../assets/svg/check-black.svg'
import styles from '../../../styles/TextFieldEditable.module.scss'

function TextFieldEditable({
  value,
  defaultValue,
  placeholder,
  onChange,
  onConfirm,
  onCancel,
  onFocusIn,
  onFocusOut,
  id,
  singleLine,
}) {
  const isControlled = value !== undefined

  // Value use as fallback when user cancels the action
  const [internalInitialValue, setInternalInitialValue] = useState('')

  // Value used for non controlled input
  const [stateValue, setStateValue] = useState('')

  // Other useful values
  const [inputHover, setInputHover] = useState(false)
  const [inputFocused, setInputFocused] = useState(false)

  // Final value to display
  const internalValue = useMemo(() => (isControlled ? value ?? '' : stateValue), [value, stateValue])

  // Disable/Enable Edit Buttons
  const [isSaving, setIsSaving] = useState(false)
  const cancelButtonDisabled = useMemo(() => isSaving || !internalValue, [isSaving, internalValue])
  const saveButtonDisabled = useMemo(
    () =>
      isSaving ||
      !internalValue ||
      (internalValue &&
        !((typeof internalValue === 'string' || internalValue instanceof String) && internalValue.length > 3)),
    [isSaving, internalValue]
  )

  // Hidden box properties
  const hiddenInputRef = useRef(null)

  useEffect(() => {
    const valueToSet = isControlled ? value ?? '' : defaultValue ?? ''
    setInternalInitialValue(valueToSet)
    setStateValue(valueToSet)
  }, [defaultValue, placeholder])

  const handleChange = async e => {
    const newValue = e.target.value
    const isValid = typeof newValue === 'string' || newValue instanceof String
    if (!isControlled && isValid) {
      setStateValue(newValue)
    }

    if (isValid) {
      onChange(newValue)
    }
  }

  const handleSave = async () => {
    setIsSaving(true)

    if (onConfirm) {
      await onConfirm()
    }

    setIsSaving(false)
  }

  const handleCancel = async () => {
    if (!isControlled) {
      setStateValue(internalInitialValue)
    }

    if (onCancel) {
      onCancel()
    }
  }

  const handleFocusIn = async () => {
    setInputFocused(true)

    if (onFocusIn) {
      onFocusIn()
    }
  }

  const handleFocusOut = async () => {
    setInputFocused(false)

    if (!isControlled && !isSaving) {
      setStateValue(internalInitialValue)
    }

    if (onCancel && !isSaving) {
      onCancel()
    }

    if (onFocusOut) {
      onFocusOut()
    }
  }

  const handleKeyPress = async e => {
    // On Enter press
    const { target } = e

    if (e.key === 'Enter' && !saveButtonDisabled) {
      e.preventDefault()
      await handleSave()
      target.blur()
      setInputFocused(false)
    }
  }

  return (
    <div className={styles.TextFieldEditable}>
      <ClickAwayListener onClickAway={handleFocusOut}>
        <div className={styles.TextFieldEditable_wrapper} onFocus={handleFocusIn}>
          <div className={styles.TextFieldEditable_inner}>
            <span className={styles.TextFieldEditable_hiddenInput} ref={hiddenInputRef}>
              {internalValue && internalValue !== '' ? internalValue : placeholder}
            </span>

            {singleLine ? (
              <input
                type="text"
                id={id}
                className={styles.TextFieldEditable_input}
                value={internalValue}
                onChange={handleChange}
                onMouseEnter={() => {
                  setInputHover(true)
                }}
                placeholder={placeholder}
                onMouseLeave={() => {
                  setInputHover(false)
                }}
                onKeyDown={handleKeyPress}
                autoComplete="off"
              />
            ) : (
              <TextareaAutosize
                id={id}
                className={styles.TextFieldEditable_input}
                onChange={handleChange}
                onMouseEnter={() => {
                  setInputHover(true)
                }}
                onMouseLeave={() => {
                  setInputHover(false)
                }}
                onKeyDown={handleKeyPress}
                value={internalValue}
                style={{ resize: 'none' }}
                placeholder={placeholder}
              />
            )}
          </div>

          {inputHover && !inputFocused && (
            <div className={styles.TextFieldEditable_buttonsWrapper}>
              <PenIcon
                className={`${styles.TextFieldEditable_inputIcon} ${styles.TextFieldEditable_inputIcon___pen}`}
              />
            </div>
          )}

          {inputFocused && !isSaving && (
            <div className={styles.TextFieldEditable_buttonsWrapper}>
              <button
                className={`${styles.TextFieldEditable_button} ${styles.TextFieldEditable_button___check}`}
                type="button"
                onClick={handleSave}
                disabled={saveButtonDisabled}
              >
                <CheckIcon
                  className={`${styles.TextFieldEditable_inputIcon} ${styles.TextFieldEditable_inputIcon___check}`}
                />
              </button>
              <button
                className={`${styles.TextFieldEditable_button} ${styles.TextFieldEditable_button___cancel}`}
                type="button"
                onClick={handleCancel}
                disabled={cancelButtonDisabled}
              >
                <CancelIcon
                  className={`${styles.TextFieldEditable_inputIcon} ${styles.TextFieldEditable_inputIcon___cancel}`}
                />
              </button>
            </div>
          )}

          {inputFocused && isSaving && (
            <div className={styles.TextFieldEditable_buttonsWrapper}>
              <CircularProgress style={{ marginRight: '0.3em' }} color="inherit" size="0.8em" />
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>
  )
}

TextFieldEditable.defaultProps = {
  saveButtonDisabled: false,
  cancelButtonDisabled: false,
  id: 'TextFieldEditable',
  singleLine: false,
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, {
  ...actionCreators,
  ...renaultProjectModeActionCreators,
})(TextFieldEditable)
