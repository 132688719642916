import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../store/actions'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import DuplicateScoringComponent from '../../../../../../components/scoring/duplicateScoringComponent'
import { duplicateScoring } from '../../../../../../../va-corejs-v3/actions/scoring'
import styles from '../../../../styles/DuplicateModal.module.scss'
import { selectProjectById } from '../../../../state/projects/selectors'

function DuplicateBenchmarkModal({
  onClose,
  product,
  projectId,
  productName,
  updateScoringDuplicateProps,
  setTemplateOnlyInReduxForScoringDuplication,
  texts,
  project,
}) {
  useEffect(() => {
    updateScoringDuplicateProps(true, product.id, productName, true)
    setTemplateOnlyInReduxForScoringDuplication(product?.template_id)
    return () => {
      // TO DO
    }
  }, [])

  const onDuplicate = async (scoringId, requestObj) => {
    if (projectId && requestObj) {
      requestObj.project_id = projectId
      requestObj.attributes = {}
      requestObj.attributes.is_configuration = false
      if (project?.category === 'lcv') requestObj.attributes.status = 'lcv_bench'
      else requestObj.attributes.status = 'car_bench'
      requestObj.attributes.is_locked = true
    }

    await duplicateScoring(scoringId, requestObj)
  }

  const Header = () => {
    return (
      <>
        <span className={`popup_title ${styles.Header_title}`}>
          {texts.duplicate_to_benchmark} <span className={styles.Header_name}>{productName}</span>
        </span>
        <div className={styles.Header_description}>{texts.duplicate_description}</div>
      </>
    )
  }
  return <DuplicateScoringComponent onDuplicate={onDuplicate} header={<Header />} onClose={onClose} />
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    device: state.device,
    newProductId: state.renaultProjectMode.createProduct.newProductId,
    projectId: state.renaultProjectMode.editProject.id,
    project: selectProjectById(state, state.renaultProjectMode.editProject.id),
  }
}

export default connect(mapStateToProps, {
  ...actionCreators,
  ...renaultProjectModeActionCreators,
})(DuplicateBenchmarkModal)
