import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import { selectProjectById } from '../../../state/projects/selectors'
import TextFieldEditable from '../Common/TextFieldEditable'
import styles from '../../../styles/EditProject.module.scss'

function ProjectName({
  project,
  changeProjectAttribute,
  updateProject,
  texts,
  resetProjectAttribute,
  touchedAttributes,
}) {
  const touchedName = touchedAttributes.name

  const value = useMemo(() => (touchedName ? touchedName.value : project.name), [touchedName, project.name])

  const handleChange = async newValue => {
    const isValid = newValue.length > 1
    await changeProjectAttribute('name', newValue, isValid)
  }

  const handleSave = async () => {
    if (touchedName) {
      const { isValid, value: touchedValue } = touchedName

      if (isValid) {
        await updateProject(project.id, {
          name: touchedValue,
        })
      }
    }
  }

  const handleCancel = async () => {
    if (touchedName !== undefined) {
      await resetProjectAttribute('name')
    }
  }

  return (
    <div className={styles.EditProjectName}>
      <TextFieldEditable
        value={value}
        placeholder={texts.project_name}
        onChange={handleChange}
        onConfirm={handleSave}
        onCancel={handleCancel}
        id="edit-project-name"
        singleLine
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    project: selectProjectById(state, state.renaultProjectMode.editProject.id),
    touchedAttributes: state.renaultProjectMode.editProject.touchedAttributes,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ProjectName)
