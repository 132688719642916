import React, { useState } from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../../store/actions'
import * as renaultProjectModeActionCreators from '../../../../../state/actions'
import TextFieldEditable from '../../../Common/TextFieldEditable'
import styles from '../../../../../styles/EditProject.module.scss'

function ProjectItemName({ projectItem, updateConfigurationCategory, texts }) {
  const initialValue = projectItem.name
  const [value, setValue] = useState(initialValue)

  const handleChange = async newValue => {
    setValue(newValue)
  }

  const handleSave = async () => {
    await updateConfigurationCategory(projectItem.id, {
      name: value,
    })
  }

  return (
    <div className={styles.ProjectItem_name}>
      <TextFieldEditable
        defaultValue={initialValue}
        onChange={handleChange}
        onConfirm={handleSave}
        placeholder={texts.new_configuration_category_title}
        id={`edit-configuration-category-name_${projectItem.id}`}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, {
  ...actionCreators,
  ...renaultProjectModeActionCreators,
})(ProjectItemName)
