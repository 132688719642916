import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { navigate } from 'gatsby'
import * as actionCreators from '../../../../../../store/actions'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import { selectProjectById } from '../../../../state/projects/selectors'
import ProjectItemIcon from '../../Common/ProjectItemIcon'
import ProjectItem from './ProjectItem'
import styles from '../../../../styles/EditProject.module.scss'
import Button from '../../Common/Button'
import PlusIcon from '../../../../../../assets/svg/plus.svg'

function ProjectItemsList({ type, project, texts, createConfigurationCategory }) {
  const snakeCaseSlug = _.replace(type, '-', '_')
  const [items, setItems] = useState([])

  useEffect(() => {
    setItems(_.filter(project.configuration_categories, item => item.type === type))
  }, [project])

  const handleCreate = async () => {
    const newConfigurationCategory = await createConfigurationCategory({
      type,
      project_id: project.id,
      name: '',
    })
    const newConfigurationUrl = `/project-mode/comparison-board?project_id=${project.id}&configuration_category_id=${newConfigurationCategory.id}`
    navigate(newConfigurationUrl)
  }

  return (
    <div className={styles.ProjectItemsList}>
      <div className={styles.ProjectItemsList_header}>
        <div className={styles.ProjectItemsList_header_icon}>
          <ProjectItemIcon type={type} width="18" height="18" />
        </div>
        <div className={styles.ProjectItemsList_header_title}>
          {texts[`configuration_category_${snakeCaseSlug}_title_plural`]}
        </div>
        <Button
          label={texts[`create_configuration_category_${snakeCaseSlug}`]}
          onClick={handleCreate}
          iconBefore={<PlusIcon />}
          size="small"
        />
      </div>

      <div className={styles.ProjectItemsList_grid}>
        {items && items.length > 0 ? (
          items.map((item, index) => <ProjectItem key={item.id} projectItem={item} index={index + 1} />)
        ) : (
          <div className={`${styles.ProjectItem} ${styles.ProjectItem___empty}`}>
            {texts[`configuration_category_${snakeCaseSlug}_empty`]}
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    project: selectProjectById(state, state.renaultProjectMode.editProject.id),
  }
}

export default connect(mapStateToProps, {
  ...actionCreators,
  ...renaultProjectModeActionCreators,
})(ProjectItemsList)
