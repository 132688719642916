export const getMediaByCollection = (object, collection) => {
  let media = []
  if (object.media !== undefined && object.media.length > 0) {
    media = object.media.filter(value => value.collection === collection)
  }

  return media
}

export const findMediaByCollection = (object, collection) => {
  const media = getMediaByCollection(object, collection)
  return media.length > 0 ? media[0] : null
}

export const findLastMediaByCollection = (object, collection) => {
  const media = getMediaByCollection(object, collection)
  return media.length > 0 ? media[media.length - 1] : null
}
