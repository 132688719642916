import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import * as actionCreators from '../../../../../../../store/actions'
import * as renaultProjectModeActionCreators from '../../../../../state/actions'
import styles from '../../../../../styles/EditProject.module.scss'
import DateField from '../../../Common/DateField'

function ProjectDueDate({ projectItem, updateConfigurationCategory }) {
  const initialValue = projectItem.due_date

  const handleSave = async updatedDueDate => {
    await updateConfigurationCategory(projectItem.id, {
      due_date: updatedDueDate && moment(updatedDueDate).format('YYYY-MM-DD'),
    })
  }

  const handleDelete = async () => {
    await updateConfigurationCategory(projectItem.id, {
      due_date: null,
    })
  }

  return (
    <div className={styles.ProjectItem_dueDate}>
      <DateField
        selected={initialValue}
        onConfirm={handleSave}
        onCancel={handleDelete}
        dateFormat="dd.MM.yyyy"
        placeholder="Due Date"
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, {
  ...actionCreators,
  ...renaultProjectModeActionCreators,
})(ProjectDueDate)
