import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import Button from '../Common/Button'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import AddMediaIcon from '../../../../../assets/svg/add-media.svg'
import { selectProjectById } from '../../../state/projects/selectors'
import { findMediaByCollection } from '../../../services/media'
import { getMediaBlobUrl } from '../../../../../../va-corejs-v3/api'
import { readUploadedFileAsBase64 } from '../../../../../../va-corejs-v3/utils'
import mediaCollectionsMap from '../../../../../../va-corejs-v3/utils/mediaCollectionsMap'
import styles from '../../../styles/EditProject.module.scss'

function Cover({ project, touchedAttributes, changeProjectAttribute, updateProject }) {
  const hiddenFileInput = useRef(null)
  const cover = findMediaByCollection(project, mediaCollectionsMap.cover_pictures)
  const url = cover ? cover.url : null
  const touchedCover = touchedAttributes.cover !== undefined ? touchedAttributes.cover.value : null

  const [preview, setPreview] = useState(null)
  const [imageIsSaving, setImageIsSaving] = useState(false)

  useEffect(() => {
    getMediaBlobUrl(url)
      .then(response => {
        setPreview(response)
      })
      .catch(() => {
        setPreview(null)
      })
  }, [])

  useEffect(() => {
    if (url !== null && !touchedCover) {
      /* getMediaBlobUrl(url)
        .then(response => {
          setPreview(response)
        })
        .catch(() => {
          setPreview(null)
        }) */
    } else if (touchedCover !== null) {
      const objectUrl = URL.createObjectURL(touchedCover)
      setPreview(objectUrl)

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)
    }

    return () => {}
  }, [url, touchedCover])

  const handlePress = () => {
    hiddenFileInput.current.click()
  }

  const imageSave = async newValue => {
    setImageIsSaving(true)

    const coverData = await readUploadedFileAsBase64(newValue)

    await updateProject(project.id, {
      media: [
        {
          collection: mediaCollectionsMap.cover_pictures,
          file: coverData.base64,
          input_type: 'base64',
        },
      ],
    })

    setImageIsSaving(false)
  }

  const handleChange = event => {
    const fileUploaded = event.target.files[0]
    const isValid = fileUploaded !== null && fileUploaded !== undefined && fileUploaded instanceof File
    changeProjectAttribute('cover', fileUploaded, isValid)

    if (isValid && !imageIsSaving) {
      imageSave(fileUploaded)
    }
  }

  return (
    <div className={styles.ProjectCover}>
      {preview && <img src={preview} alt="" className={styles.ProjectCover_image} />}
      {imageIsSaving && (
        <div className={styles.ProjectCover_uploadingOverlay}>
          <CircularProgress color="inherit" size={50} />
        </div>
      )}
      <Button
        label="Define cover"
        className={styles.ProjectCover_button}
        iconBefore={<AddMediaIcon />}
        onClick={handlePress}
        size="small"
        disabled={imageIsSaving}
      />
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept=".jpg,.jpeg,.png"
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    project: selectProjectById(state, state.renaultProjectMode.editProject.id),
    touchedAttributes: state.renaultProjectMode.editProject.touchedAttributes,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Cover)
