import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../../state/actions'

function SearchBar({ query, updateSearchProductsParams, searchProducts, texts }) {
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(query)
  }, [])

  const debouncedSearch = React.useRef(
    _.debounce(async updatedValue => {
      await updateSearchProductsParams({
        query: updatedValue,
      })

      await searchProducts()
    }, 300)
  ).current

  const handleChange = async e => {
    const updatedValue = e.target.value
    setValue(updatedValue)
    if (updatedValue.length > 2 || updatedValue.length === 0) {
      debouncedSearch(updatedValue)
    }
  }

  return (
    <div className="search-bar__wrapper">
      <input className="search-bar" type="text" value={value} onChange={handleChange} placeholder={texts.search} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    query: state.renaultProjectMode.searchProducts.params.query,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(SearchBar)
