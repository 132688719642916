import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../store/actions'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import DuplicateScoringComponent from '../../../../../../components/scoring/duplicateScoringComponent'
import { duplicateScoring } from '../../../../../../../va-corejs-v3/actions/scoring'
import styles from '../../../../styles/DuplicateModal.module.scss'

function DuplicateConfigurationModal({
  onClose,
  product,
  projectId,
  productName,
  updateScoringDuplicateProps,
  setTemplateOnlyInReduxForScoringDuplication,
  texts,
}) {
  useEffect(() => {
    updateScoringDuplicateProps(true, product.id, productName, true)
    setTemplateOnlyInReduxForScoringDuplication(product?.template_id)
    return () => {
      // TO DO
    }
  }, [])

  const onDuplicate = async (scoringId, requestObj) => {
    if (projectId && requestObj) {
      requestObj.project_id = projectId
      requestObj.attributes = {}
      requestObj.attributes.is_configuration = true
      requestObj.attributes.status = 'configuration'
      requestObj.attributes.is_locked = false
    }

    await duplicateScoring(scoringId, requestObj)
  }

  const Header = () => {
    return (
      <>
        <span className={`popup_title ${styles.Header_title}`}>
          {texts.duplicate_to_configuration} <span className={styles.Header_name}>{productName}</span>
        </span>
        <div className={styles.Header_description}>{texts.duplicate_description}</div>
      </>
    )
  }
  return <DuplicateScoringComponent onDuplicate={onDuplicate} header={<Header />} onClose={onClose} />
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    device: state.device,
    newProductId: state.renaultProjectMode.createProduct.newProductId,
    projectId: state.renaultProjectMode.editProject.id,
  }
}

export default connect(mapStateToProps, {
  ...actionCreators,
  ...renaultProjectModeActionCreators,
})(DuplicateConfigurationModal)
