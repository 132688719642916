import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import ProjectCover from './ProjectCover'
import ProjectName from './ProjectName'
import styles from '../../../styles/EditProject.module.scss'

function Header() {
  return (
    <div className={styles.EditProjectPage_header}>
      <ProjectCover />
      <div className={styles.EditProjectPage_header_right}>
        <ProjectName />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Header)
