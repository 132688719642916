import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../../state/actions'
import Button from '../../../Common/Button'

function ClearButton({ selectedIds, searchProductsDeselectAllProducts, texts }) {
  return (
    <Button
      className="products-search-modal__clear-all"
      label={texts.clear_all}
      color="secondary"
      size="large"
      onClick={() => {
        searchProductsDeselectAllProducts()
      }}
      disabled={selectedIds.length === 0}
    />
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    selectedIds: state.renaultProjectMode.searchProducts.selectedIds,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ClearButton)
