import React, { useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as actionCreators from '../../../../../../../store/actions'
import * as renaultProjectModeActionCreators from '../../../../../state/actions'
import ContextualMenu from '../../../Common/ContextualMenu'
import DuplicateIcon from '../../../../../../../assets/svg/duplicate.svg'
import XIcon from '../../../../../../../assets/svg/x.svg'
import { POPOVER_POSITION_BOTTOM_RIGHT } from '../../../Common/Popover'
import styles from '../../../../../styles/EditProject.module.scss'
import Dialog from '../../../Common/Dialog'
import Config from '../../../../../config.json'
import IconLock from '../../../../../../../assets/svg/lock_product_menu_black.svg'

function ProjectItemMenu({
  configurationCategory,
  anchorEl,
  onClose,
  duplicateConfigurationCategory,
  deleteConfigurationCategory,
  updateConfigurationCategory,
  texts,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openLockDialog, setOpenLockDialog] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmLock, setConfirmLock] = useState(false)

  const otherConfigurationCategoriyTypes = Config.configuration_categories.filter(
    type => type !== configurationCategory.type
  )
  const [otherConfigurationCategoriyType] = otherConfigurationCategoriyTypes ?? []
  const otherTypeSlug = _.replace(otherConfigurationCategoriyType, '-', '_')

  const handleClickMenuRemove = async () => {
    setOpenDeleteDialog(true)
    setConfirmDelete(false)
  }

  const handleClickDialogRemove = async () => {
    setConfirmDelete(true)
  }

  const handleClickConfirmRemove = async () => {
    // setIsLoading(true)
    try {
      await deleteConfigurationCategory(configurationCategory.id, configurationCategory.project_id)
      // setOpenDeleteDialog(false)
    } catch (error) {
      // TODO handle error
      console.log(error)
      setOpenDeleteDialog(false)
    }
  }

  const handleDuplicate = async () => {
    setIsLoading(true)

    try {
      await duplicateConfigurationCategory(configurationCategory)
      setIsLoading(false)
      if (onClose) onClose()
    } catch (error) {
      // TODO handle error
      console.log(error)
      if (onClose) onClose()
    }
  }

  const handleDuplicateToAnotherType = async () => {
    setIsLoading(true)
    try {
      await duplicateConfigurationCategory(configurationCategory, { type: otherConfigurationCategoriyType })
      setIsLoading(false)
      if (onClose) onClose()
    } catch (error) {
      // TODO handle error
      console.log(error)
      if (onClose) onClose()
    }
  }

  const handleClickMenuLock = () => {
    setOpenLockDialog(true)
    setConfirmLock(false)
  }

  const handleClickDialogLock = async () => {
    setConfirmLock(true)
  }

  const handleClickConfirmLock = async () => {
    const newIsLocked = !configurationCategory.is_locked
    await updateConfigurationCategory(
      configurationCategory.id,
      { is_locked: newIsLocked },
      configurationCategory.project_id
    )
    if (onClose) onClose()
  }

  const handleClose = () => {
    if (!openDeleteDialog && !openLockDialog && onClose) onClose()
  }

  const lockActionText = configurationCategory.is_locked ? texts.unlock : texts.lock
  const lockConfirmText = configurationCategory.is_locked
    ? `${texts.are_you_really_sure_unlock} ${configurationCategory.name ?? texts.new_configuration_category_title} ? ${
        texts.all_included_configurations_will_be_unlocked
      }.`
    : `${texts.are_you_really_sure_lock} ${configurationCategory.name ?? texts.new_configuration_category_title} ? ${
        texts.all_included_configurations_will_be_locked
      }.`

  const lockMessageText = configurationCategory.is_locked
    ? `${texts.are_you_sure_unlock} ${configurationCategory.name ?? texts.new_configuration_category_title} ?`
    : `${texts.are_you_sure_lock} ${configurationCategory.name ?? texts.new_configuration_category_title} ?`

  return (
    <>
      <ContextualMenu
        className={styles.ProjectItem_menu_container}
        anchorEl={anchorEl}
        fixed={false}
        onClickAway={handleClose}
        position={POPOVER_POSITION_BOTTOM_RIGHT}
        closeOnScrollId="editProjectPageScroller"
        items={[
          {
            label: texts.duplicate,
            disabled: isLoading,
            icon: <DuplicateIcon />,
            onClick: handleDuplicate,
          },
          {
            label: `${texts.duplicate_to} ${texts[`configuration_category_${otherTypeSlug}_title`]}`,
            disabled: isLoading,
            icon: <DuplicateIcon />,
            onClick: handleDuplicateToAnotherType,
          },
          {
            label: configurationCategory.is_locked ? `${texts.unlock_configuration}` : `${texts.lock_configuration}`,
            disabled: isLoading,
            icon: <IconLock />,
            onClick: handleClickMenuLock,
          },
          {
            label: texts.delete,
            disabled: isLoading,
            color: 'primary',
            icon: <XIcon />,
            onClick: handleClickMenuRemove,
          },
        ]}
      />

      <Dialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        onConfirm={confirmDelete ? handleClickConfirmRemove : handleClickDialogRemove}
        closeOnConfirm={false}
        title={`${texts.delete}  ${configurationCategory.name ?? texts.new_configuration_category_title}`}
        text={
          confirmDelete
            ? `${texts.are_you_really_sure_delete} ${configurationCategory.name ??
                texts.new_configuration_category_title} ? ${texts.will_be_lost_permanently}.`
            : `${texts.are_you_sure_delete} ${configurationCategory.name ?? texts.new_configuration_category_title} ?`
        }
        textColor={confirmDelete && 'red'}
        confirmButtonText={texts.delete}
      />

      <Dialog
        open={openLockDialog}
        setOpen={setOpenLockDialog}
        onConfirm={confirmLock ? handleClickConfirmLock : handleClickDialogLock}
        closeOnConfirm={false}
        title={`${lockActionText}  ${configurationCategory.name ?? texts.new_configuration_category_title}`}
        text={confirmLock ? lockConfirmText : lockMessageText}
        textColor={confirmLock && 'red'}
        confirmButtonText={lockActionText}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, {
  ...actionCreators,
  ...renaultProjectModeActionCreators,
})(ProjectItemMenu)
