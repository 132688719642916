import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import { selectProjectById, selectProjectMapping } from '../../../state/projects/selectors'
import Config from '../../../config.json'
import ProjectItemsList from './ProjectItemsList'
import styles from '../../../styles/EditProject.module.scss'
import ProjectProductsList from './ProjectProductsList'

function Body() {
  return (
    <div id="editProjectPageScroller" className={styles.EditProjectPage_body}>
      {Config.configuration_categories.map(type => (
        <ProjectItemsList key={type} type={type} />
      ))}

      <ProjectProductsList />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    project: selectProjectById(state, state.renaultProjectMode.editProject.id),
    mapping: selectProjectMapping(state, state.renaultProjectMode.editProject.id),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Body)
