import _ from 'lodash'

export const isProductLocked = (product, project) => {
  if (!product.is_configuration) {
    return true
  }

  const found = _.find(project.configuration_categories, configurationCategory => {
    return configurationCategory.product_ids.includes(product.id) && configurationCategory.is_locked === true
  })

  return !!found
}
