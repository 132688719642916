import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import '../../../../styles/products-search-modal.scss'
import _ from 'lodash'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import Header from './Header'
import ModalBody from './Body'
import Footer from './Footer'
import SpinnerFiller from '../../Common/SpinnerFiller'
import { selectProjectMappingWithoutConfCategory } from '../../../../state/projects/selectors'

function AddBenchmarkModal({
  title,
  onClose,
  searchProducts,
  onConfirm,
  isSyncing,
  initSelectedProducts,
  updateSearchProductsParams,
  mappingWithoutConfCategory,
}) {
  const [initialized, setInitialized] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // exclude Configurations alredy related benchmark
    initSelectedProducts([])
    updateSearchProductsParams({
      excludedProductIds: _.map(mappingWithoutConfCategory, item => item.product_id),
    })
    setInitialized(true)

    return () => {
      updateSearchProductsParams({
        excludedProductIds: null,
      })
    }
  }, [])

  useEffect(() => {
    async function _search() {
      setIsLoading(true)
      await searchProducts()
      setIsLoading(false)
    }

    if (initialized) {
      // initialized check aim to avoid double search during mounting
      _search()
    }
  }, [initialized])

  const handleConfirm = async ids => {
    await onConfirm(ids)
    onClose()
  }

  return (
    <div className="products-search-modal__wrapper">
      <div className="products-search-modal">
        <Header title={title} onCloseClick={onClose} />
        <ModalBody />
        <Footer onConfirm={handleConfirm} />
        {(isSyncing || isLoading) && <SpinnerFiller width="28" />}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    isLoading: state.renaultProjectMode.searchProducts.isLoading,
    selectedIds: state.renaultProjectMode.searchProducts.selectedIds,
    mappingWithoutConfCategory: selectProjectMappingWithoutConfCategory(state, state.renaultProjectMode.editProject.id),
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(AddBenchmarkModal)
