import React, { useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as actionCreators from '../../../../../../store/actions'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import { selectProjectById, selectProjectMappingWithoutConfCategory } from '../../../../state/projects/selectors'
import styles from '../../../../styles/EditProject.module.scss'
import Button from '../../Common/Button'
import PlusIcon from '../../../../../../assets/svg/plus.svg'
import ProductIcon from '../../../../../../assets/svg/document.svg'
import ProjectProduct from './ProjectProduct'
import { LoadingBar } from '../../../../../../components/loading_bar'
import CreateConfigurationModal from '../CreateConfigurationModal'
import AddBenchmarkModal from '../AddBenchmarkModal'

function ProjectProductsList({ project, texts, addProductsToProject, fetchProducts, mapping }) {
  const [searchModalOpen, setSearchModalOpen] = useState(false)
  const [configurationModalOpen, setConfigurationModalOpen] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const handleAddBenchmark = async ids => {
    setIsLoading(true)
    await fetchProducts({ _id: ids }, ['media']) // Get the products by id with the scoring tree included
    await addProductsToProject(ids, project.id)
    setIsLoading(false)
  }

  return (
    <>
      <div className={styles.ProjectItemsList}>
        <div className={styles.ProjectItemsList_header}>
          <div className={styles.ProjectItemsList_header_icon}>
            <ProductIcon />
          </div>
          <div className={styles.ProjectItemsList_header_title}>{texts.project_products_list}</div>
          <Button
            label={texts.create_configuration}
            onClick={() => setConfigurationModalOpen(true)}
            iconBefore={<PlusIcon />}
            size="small"
          />
          <Button
            label={texts.project_add_benchmark}
            onClick={() => setSearchModalOpen(true)}
            iconBefore={<PlusIcon />}
            size="small"
            color="secondary"
          />
        </div>

        <div className={styles.ProjectItemsList_list}>
          {isLoading && (
            <div className={styles.ProjectItemsList_list_loading}>
              <LoadingBar />
            </div>
          )}
          {!isLoading &&
            mapping.map(item => (
              <ProjectProduct key={item.product_id} productId={item.product_id} projectId={item.project_id} />
            ))}
        </div>
      </div>
      {project && configurationModalOpen && (
        <CreateConfigurationModal
          title={texts.new_configuration}
          closeCallback={() => setConfigurationModalOpen(false)}
        />
      )}
      {project && searchModalOpen && (
        <AddBenchmarkModal
          title={project.name ?? texts.project_name}
          project={project}
          onClose={() => setSearchModalOpen(false)}
          onConfirm={handleAddBenchmark}
          preSelectedIds={_.map(mapping, item => item.product_id)}
          isSyncing={isLoading}
        />
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    project: selectProjectById(state, state.renaultProjectMode.editProject.id),
    mapping: selectProjectMappingWithoutConfCategory(state, state.renaultProjectMode.editProject.id),
  }
}

export default connect(mapStateToProps, {
  ...actionCreators,
  ...renaultProjectModeActionCreators,
})(ProjectProductsList)
